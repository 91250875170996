import { useCallback } from "react";
import Modal from "../Modal";
import { LoadingIcon } from "../../icons";
import "../../styles/Animation.css";
import personSlideSrc from '../../images/square-selfie-slide.webp';
import { PhotoLoading } from "./PhotoLoading";
import { useTranslation } from "react-i18next";
import { PhotoLoadingError } from "./PhotoError";
import { ScorePreview } from "./ScorePreview";
import { useGetStage, useGoOnPreview, useGoToResult, useLoadProfilePhoto, usePhotoState, useProgress, useReset, useUploadFullFacePhoto } from "../../context/PhotoLoadingContext";
import { Paywall } from "./Paywall";

export const MainPhotoLoadingModal = ({ opened, onClose }) => {
    const { t } = useTranslation();
    const uploadFullFacePhoto = useUploadFullFacePhoto();
    const state = usePhotoState();
    const getStage = useGetStage();
    const goOnPreview = useGoOnPreview();
    const loadProfilePhoto = useLoadProfilePhoto();
    const goToResult = useGoToResult();
    const progress = useProgress();

    const reset = useReset();

    const closeHandler = useCallback(() => {
        reset();
        onClose();
    }, [onClose, reset])

    const stage = getStage(state);

    const renderContent = (state) => {
        const stage = getStage(state);
        switch (stage) {
            case 'loading':
                return (
                    <>
                        <div className=" absolute top-[calc(50%-12px)] left-0 right-0 h-[127px]">
                            <LoadingIcon className="loader absolute left-[calc(50%-12px)]" />
                            {progress && <>
                                <div className='h-[72px] mt-[55px] text-[18px] tracking-[normal] leading-[1.33] font-header font-bold text-[#232948] text-center '>
                                    Process could take
                                    <br />
                                    a few minutes
                                    <br />
                                    {progress}
                                </div>
                            </>}
                        </div>


                    </>
                )
            case 'fullFaceError':
                return (
                    <PhotoLoadingError onPhotoLoaded={uploadFullFacePhoto} text={state.photoFullFaceError.text} exampleSrc={state.photoFullFaceError.url} />
                )
            case 'profileError':
                return (
                    <PhotoLoadingError onPhotoLoaded={loadProfilePhoto} text={state.photoProfileError.text} exampleSrc={state.photoProfileError.url} />
                )
            case 'profileLoad':
                return (
                    <PhotoLoading onPhotoLoaded={loadProfilePhoto} text={t('UPLOAD_SELFIE_3_STEP_1_TITLE')} exampleSrc={personSlideSrc} />
                )
            case 'preview':
                return (
                    <ScorePreview image={state.photoFullFace?.url} onClickGetScore={goOnPreview} score={state.previewData.score} status={state.previewData.status} />
                )
            case 'paywall':
                return (
                    <Paywall onClickContinue={goToResult} />
                )
            default:
                return (
                    <div className="self-center">
                        <LoadingIcon className="loader" />
                    </div>
                )
        }
    }

    return (
        <Modal shouldCloseOnOutline={false} isOpen={opened} onClose={closeHandler} hideCloseButton={stage === 'loading'} contentClassName="min-h-[477px] relative">
            <div className="flex w-full justify-center">
                <div className="w-[241px] flex flex-col pt-[40px] items-center pb-[30px]">
                    {renderContent(state)}
                </div>
            </div>
        </Modal>
    )
}