import React from 'react';
import './styles.css';
import CircleProgressBar from '../ProgressBar';

const ScoreDisplay = ({ raitings }) => {
    const getColor = (score) => {
        return '#0175ff';
    };

    const renderScoreBlock = (title, score) => (
        <div className='w-[140px] h-[140px] bg-[#242a46] rounded-[16px] flex justify-center'>
            <div className="flex self-center">
                <div className="w-full">
                    <div className="text-[16px] w-full leading-[19px] text-center font-[600] text-[#fff]">
                        {title}
                    </div>
                    <div className="relative mt-[8px] flex justify-center self-center items-center w-[80px] h-[80px]">
                        <CircleProgressBar className="absolute top-0 left-0" score={score} />
                        <div className="text-[30px] text-[#fff] font-bold">
                            {score}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="w-[290px] flex flex-wrap gap-[10px] mt-[30px]">
            {
                raitings.map(({ caption, score }) => renderScoreBlock(caption, score))
            }
        </div>
    );
};

export default ScoreDisplay;