import { getColorByType, getTypeByScore, renderWithLineBreaks } from "../../utils";
import { ChartLoadingIcon, ReloadWhiteIcon } from "../../icons";
import { SpeedometerCombined } from "../Speedometer";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useShowExpressScore } from "../../context";

export const Paywall = ({
    onClickContinue,
}) => {

    const { t } = useTranslation();
    const colorOfScore = getColorByType('PROCESSING')

    const pay = useCallback(() => {
        onClickContinue('unlock');
        window.dataLayer.push({ 'event': 'unlock_for_$1' });
    }, [onClickContinue])

    const showExpress = useShowExpressScore();

    return (
        <>
            {showExpress ? <>
                <div className="text-[26px] font-[600] text-[#232948] leading-[normal] text-center">
                    Reveal Your
                    <br />
                    Advanced Score
                </div>
                <div className='rounded-[32px] mt-[21px] bg-[#242a46] h-[240px] w-[240px] flex flex-col justify-center items-center'>
                    <div className="flex flex-wrap w-[150px] gap-[22px_30px]">
                        <div className="flex flex-col w-[60px]">
                            <div className="text-[12px] text-[#eee] text-center w-full">
                                {renderWithLineBreaks(t('PAYWALL_ANALYSIS_CAPTION_1'))}
                            </div>

                            <div className="mt-[6px]">
                                <ChartLoadingIcon  />
                            </div>
                        </div>

                        <div className="flex flex-col w-[60px]">
                            <div className="text-[12px] text-[#eee] text-center w-full">
                                {renderWithLineBreaks(t('PAYWALL_ANALYSIS_CAPTION_2'))}
                            </div>

                            <div className="mt-[6px]">
                                <ChartLoadingIcon  />
                            </div>
                        </div>

                        <div className="flex flex-col w-[60px]">
                            <div className="text-[12px] text-[#eee] text-center w-full">
                                {renderWithLineBreaks(t('PAYWALL_ANALYSIS_CAPTION_3'))}
                            </div>

                            <div className="mt-[6px]">
                                <ChartLoadingIcon  />
                            </div>
                        </div>

                        <div className="flex flex-col w-[60px]">
                            <div className="text-[12px] text-[#eee] text-center w-full">
                                {renderWithLineBreaks(t('PAYWALL_ANALYSIS_CAPTION_4'))}
                            </div>

                            <div className="mt-[6px]">
                                <ChartLoadingIcon />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-[20px] flex">
                    <button onClick={pay} className='font-bold ml-[10px] w-[240px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[32px]'>
                        {renderWithLineBreaks(t('PAYWALL_1_BUTTON_UNLOCK'))}
                    </button>
                </div>

            </> : <>

                <div className="text-[26px] font-[600] text-[#232948] leading-[normal] text-center">
                    Reveal
                    <br />
                    Your Results
                </div>
                <div className='rounded-[32px] mt-[21px] bg-[#242a46] h-[241px] w-[241px] pt-[44px] flex flex-col justify-center items-center'>
                    <SpeedometerCombined onlyAnimation={true} />
                    <div style={{ backgroundColor: colorOfScore.bg, color: colorOfScore.text }} className="text-[15px] mt-[12px] h-[28px] rounded flex justify-center items-center px-3 text-white">
                        {renderWithLineBreaks(t('PAYWALL_1_STATUS'))}
                    </div>
                </div>
                <div className="mt-[20px] flex">
                    <button onClick={pay} className='font-bold ml-[10px] w-[240px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[32px]'>
                        {renderWithLineBreaks(t('PAYWALL_1_BUTTON_UNLOCK'))}
                    </button>
                </div>
            </>}
        </>
    )
}