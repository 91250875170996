import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SpeedometerCombined } from "../Speedometer";
import { getColorByType, getTypeByScore, imageUrlToBase64, isTelegram, renderWithLineBreaks } from "../../utils";
import { BackIcon, ShareIcon, DownloadIcon, ReloadIcon, DangerIcon, PDFIcon } from "../../icons";
import { useHistory } from "react-router-dom";
import { useBlockSettings, useSetPhoto, useShowSharing } from "../../context";
import "../../styles/Animation.css";
import * as animationData from '../../animations/loading-character.json';
import Lottie from "react-lottie";
import { SharingBlock } from "./SharingBlock";
import { makeRequest } from "../../utils/request";
import { useTranslation } from "react-i18next";
import ScoreDisplay from "../BlockScore";
import html2canvas from 'html2canvas-pro';
import { TipsModal } from "../ModalTips";
import { FeedbackModal } from "../ModalFeedback";
import uploadExampleSrc from '../../images/two-sides-upload-example.webp';
import { PhotoLoadingModal } from "../ModalLoading";
import { AnalysisPreview, AnalysisPreviewModal } from "../ModalAnalysisPreview";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ReferalBlock } from "./ReferalBlock";
import { TipsPreviewModal } from "../ModalTipsPreview";
import CircleProgressBar from "../ProgressBar";
import { InsightsModal } from "../ModalInsights";


export const Result = ({
    photoSrc,
    onGoAnalysis,
    ratings,
    isAuthorSession,
    score,
    status,
    isCharacterLoading,
    characterUrl,
    characterProgress,
    timeoutFallenFetch,
    photoId,
    isPreview,
    onUpdateScore,
    hideCharacter,
    potential
}) => {

    const { t } = useTranslation();
    const { title, showBlock, caption } = useBlockSettings();
    const renderedResult = useRef()
    const uploadBlock = useRef();
    const [image, setImage] = useState(photoSrc)
    const history = useHistory();
    const setPhoto = useSetPhoto();
    const showSharing = useShowSharing();

    const [opened, setOpenModal] = useState(false);
    const [openedInsightsModal, setOpenedInsightsModal] = useState(false);

    const openInsightModal = useCallback(() => {
        setOpenedInsightsModal(true);
    }, [])
    const closeInsightModal = useCallback(() => {
        setOpenedInsightsModal(false);
    }, [])

    const [FeedbackModalOpened, setFeedbackModalOpened] = useState(false);

    const [uploadModalOpened, setUploadModalOpened] = useState(false);
    const [analysisPreviewModalOpened, setAnalysisPreviewModalOpened] = useState(false);

    const [tipsPreviewModalOpened, setTipsPreviewModalOpened] = useState(false);

    const scrollToUpload = useCallback(() => {
        if (uploadBlock.current) {
            uploadBlock.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [])

    const openAnalysisPreviewModal = useCallback(() => {
        setAnalysisPreviewModalOpened(true);
    }, [])

    const closeAnalysisPreviewModal = useCallback(() => {
        scrollToUpload();
        setAnalysisPreviewModalOpened(false);
    }, [scrollToUpload])


    const openTipsPreviewModal = useCallback(() => {
        setTipsPreviewModalOpened(true);
    }, [])

    const closeTipsPreviewModal = useCallback(() => {
        scrollToUpload();
        setTipsPreviewModalOpened(false);
    }, [scrollToUpload])

    const closeUploadModal = useCallback(() => {
        setUploadModalOpened(false);
    }, []);

    const openUploadModal = useCallback(() => {
        setUploadModalOpened(true);
    }, []);


    const closeFeedbackModal = useCallback(() => {
        setFeedbackModalOpened(false);
    }, [])

    const closeModal = useCallback(() => {
        setOpenModal(false)
    }, [])

    const colorTypeOfScore = getTypeByScore(score);
    const colorOfScore = getColorByType(colorTypeOfScore);


    const defaultLottieOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        className: "self-center",
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        if (photoSrc) {
            imageUrlToBase64(photoSrc).then((val) => {
                setImage(val)
            });
        }
    }, [photoSrc])

    const getImage = useCallback(() => {
        if (renderedResult.current) {
            html2canvas(renderedResult.current).then((canvas) => {

                let img = canvas.toDataURL("image/jpeg");
                // window.open(img);

                const linkSource = img;
                const downloadLink = document.createElement('a');
                document.body.appendChild(downloadLink);

                downloadLink.href = linkSource;
                downloadLink.target = '_self';
                downloadLink.download = 'saved';
                downloadLink.click();
            }).catch((e) => {
                console.error(e);
            })
        }
    }, [])

    const goToMain = useCallback(() => {
        history.push('/')
        setPhoto('')
    }, [history, setPhoto])

    const onShare = useCallback(async () => {
        window.dataLayer.push({ 'event': 'share' });

        makeRequest('/user/update', 'post', {
            tap: 'share'
        });

        if (isTelegram()) {
            const a = document.createElement("a");
            a.href = `https://t.me/share/url?url=${encodeURI('t.me/yasss_app_bot/yasss')}?startapp=${photoId}&text=${'Check Out How the AI Rates Me!'}`;
            a.target = '_blank';
            a.rel = 'noopener noreferrer';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            return;
        }

        if (!navigator.canShare) {
            return;
        }
        const url = window.location.href;
        const shareData = {
            title: "FaceQ: AI Face Score",
            text: "See How You Rate with AI",
            url,
        };
        try {
            await navigator.share(shareData);
        } catch (err) {
            console.error('failed sharing:', err);
        }
    }, [photoId])

    const downloadLinkPromise = useMemo(async () => {
        if (characterUrl) {
            try {
                const blob = await fetch(characterUrl).then(res => res.blob());
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url
                const name = (new URL(characterUrl)).pathname.replace('/tmp/', '');
                a.download = name;
                return a;
            } catch (e) {
                console.error(e);
            }
        }
    }, [characterUrl])

    const onDownloadImage = useCallback(async () => {
        if (downloadLinkPromise) {
            downloadLinkPromise.then((link) => {
                if (link) {
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
        }
        return;
    }, [downloadLinkPromise])

    const onReload = useCallback(() => {
        if (!characterUrl && timeoutFallenFetch) {
            window.location.reload();
        }
    }, [timeoutFallenFetch, characterUrl]);


    const onGetFullAnalysis = useCallback(() => {

        if (isAuthorSession) {
            makeRequest('/user/update', 'post', {
                tap: 'analysis'
            });
        }


        openInsightModal();
    }, [isAuthorSession, openInsightModal])

    const onGetTips = useCallback(() => {
        if (isAuthorSession) {
            makeRequest('/user/update', 'post', {
                tap: 'tips'
            });
        }
        openInsightModal();

        // setOpenModal(true);
        // history.push(`/pay-tips/${photoId}`);
    }, [isAuthorSession, openInsightModal])

    const runCompatibility = useCallback(() => {
        makeRequest('/user/update', 'post', {
            tap: 'compatibility'
        });
        alert(`This method isn't implemented yet`);
    }, [])

    const onClickInsights = useCallback(() => {
        makeRequest('/user/update', 'post', {
            tap: 'report'
        });
        alert(`This method isn't implemented yet`);
    }, [])

    return <div className={`${isTelegram() ? 'bg-[#FFFFFF]' : 'bg-[#f6f7f8]'} relative w-full flex flex-col overflow-hidden items-center`} >
        {isAuthorSession && <div className="flex sm:mt-[42px] mt-[20px] w-[285px] justify-between">
            <button onClick={goToMain} className="bg-[#b6b9c2] rounded-[50%] flex justify-center items-center w-[38px] h-[38px]">
                <a href="/"><BackIcon /></a>
            </button>

            <button onClick={onShare} className={`bg-[#b6b9c2] items-center justify-center rounded-[50%] flex w-[38px] h-[38px]`}>
                <ShareIcon />
            </button>
        </div>}

        <div className={`relative z-10 h-[430px] flex flex-col pt-[31px] w-full rounded-[50px] bg-[#242a46] mt-[40px] max-w-[335px] ${isAuthorSession ? 'mt-[40px]' : 'mt-[44px]'} `}>
            <div className='absolute z-2 rounded-t-[32px] w-[315px] left-[10px] top-[-12px] h-[40px] bg-[rgba(36,42,70,0.5)]'></div>
            <div className='absolute z-1 rounded-t-[32px] w-[300px] left-[18px] top-[-24px] h-[40px] bg-[rgba(36,42,70,0.3)]'></div>

            <img
                alt=""
                src={image}
                className="self-center bg-cover border-[2px] border-solid font-[15px] border-[#6c7183] w-[180x] h-[180px] rounded-[50%] bg-no-repeat bg-center"
            />

            <div className="flex justify-center mt-[26px]">
                <SpeedometerCombined value={score || 50} />
            </div>

            <div className="flex justify-center mt-[21px]">
                {status && (<div className=" font-['Montserrat'] text-[30px] font-bold h-[28px] rounded flex justify-center items-center px-3 text-white">
                    {status}
                </div>)}
            </div>
        </div>

        {(!isTelegram() && isAuthorSession && showSharing) && <SharingBlock characterUrl={characterUrl} />}

        <div className="max-w-[260px] text-[#242a46] mt-[40px] text-base leading-[normal] font-bold flex justify-center text-center">
            {renderWithLineBreaks(t('RESULT_RATINGS_TITLE'))}
        </div>
        {/* <div className="w-[291px] mt-[30px] px-[30px] py-[25px] bg-[#242a46] flex rounded-[16px]">
            <div className="flex self-center">
                <div className="w-[100px]">
                    <div className="text-[16px] w-full text-center text-[#fff]">
                        {renderWithLineBreaks(t('RESULT_SCORE_TITLE'))}
                    </div>
                    <div className="relative mt-[6px] flex justify-center items-center w-[80px] h-[80px]">
                        <CircleProgressBar className="absolute top-0 left-0" score={score} />
                        <div className="text-[30px] text-[#fff] font-bold">
                            {score}
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-[100px] ml-[32px]">
                <div className="text-[16px] w-full text-center text-[#fff]">
                    {renderWithLineBreaks(t('RESULT_POTENTIAL_TITLE'))}
                </div>
                <div className="relative mt-[6px] flex justify-center items-center w-[80px] h-[80px]">
                    <CircleProgressBar className="absolute top-0 left-0" score={potential} />
                    <div className="text-[30px] text-[#fff] font-bold">
                        {potential}
                    </div>
                </div>
            </div>
        </div> */}

        {/* <div className="max-w-[260px] text-[#242a46] mt-[40px] text-base leading-[normal] font-bold flex justify-center text-center">
            {renderWithLineBreaks(t('RESULT_RATINGS_TITLE'))}
        </div> */}
        <ScoreDisplay
            raitings={ratings}
        />

        {isAuthorSession && <button onClick={onGetFullAnalysis} className="w-full max-w-[290px] text-[white] h-[60px] mt-[10px] text-[18px] font-[600] rounded-[16px] flex justify-center items-center bg-[#0175ff]">
            {renderWithLineBreaks(t('RESULT_ANALYSIS_BUTTON'))}
        </button>}




        {
            !hideCharacter && <>
                <div className="max-w-[260px] text-[#242a46] mt-[40px] text-base leading-[normal] font-bold flex justify-center text-center">
                    {renderWithLineBreaks(t('RESULT_IMAGE_TITLE'))}
                </div>

                <div onClick={onReload} className="mt-[30px] w-[290px] relative h-[290px] rounded-[16px] bg-[rgba(36,42,70,0.1)] flex justify-center overflow-hidden" >
                    {
                        (isCharacterLoading && !characterUrl && !timeoutFallenFetch) && <div className="self-center w-[160px] relative left-[-2px] top-[14px]" >
                            <Lottie options={defaultLottieOptions}
                                height={90}
                                width={160}
                            />
                            <div className="h-[24px] text-[18px] mt-[4px] font-header font-bold color-[#000000] text-center">{characterProgress}</div>
                        </div>
                    }
                    {
                        (!isCharacterLoading && characterUrl) && <>
                            <button onClick={onDownloadImage} className={`bg-[rgba(0,0,0,0.3)] items-center justify-center rounded-[50%] flex w-[44px] h-[44px] absolute top-[10px] right-[10px]`}>
                                <DownloadIcon />
                            </button>
                            <img src={characterUrl} className="w-full h-full" alt="" />
                        </>
                    }
                    {
                        (!characterUrl && timeoutFallenFetch) && <div className="self-center cursor-pointer mt-[23px]" >
                            <ReloadIcon />
                            <div className="text-[18px] mt-[19px] font-header font-bold text-[#595a5b] text-center">{renderWithLineBreaks(t('RELOAD'))}</div>
                        </div>
                    }
                </div>

                {(isAuthorSession) && <button onClick={onGetTips} className="w-full max-w-[290px] text-[white] h-[60px] mt-[10px] text-[18px] font-[600] rounded-[16px] flex justify-center items-center bg-[#0175ff]">
                    {renderWithLineBreaks(t('RESULT_TIPS_BUTTON'))}
                </button>}
            </>
        }

        {/* {
            (isAuthorSession && !isPreview) && (
                <div className="bg-[#811883] rounded-[16px] mt-[40px] w-full max-w-[290px] p-[32px_24px_20px_26px] flex flex-col items-center relative">
                    <PDFIcon className="w-[30px] h-[18px] absolute top-[10px] right-[10px]" />

                    <img alt="" src={image} className="w-[94px] h-[94px] border-[2px] rounded-[50%] border-solid border-[#fff]" />
                    <div className="text-[28px] font-['Montserrat'] font-bold leading-[normal] text-white mt-[9px] text-center">
                        {renderWithLineBreaks(t('SCORE_REPORT_TITLE'))}
                    </div>
                    <div className="text-white leading-[normal] text-[18px] mt-[12px] text-center">
                        {renderWithLineBreaks(t('SCORE_REPORT_DESCRIPTION'))}
                    </div>
                    <button onClick={onClickInsights} className="bg-[#0076ff] rounded-[16px] flex justify-center items-center mt-[26px] w-full h-[60px] text-[20px] text-white font-bold">
                        {renderWithLineBreaks(t('SCORE_REPORT_BUTTON'))}
                    </button>
                </div>
            )
        } */}
        {isPreview &&
            <>
                {isAuthorSession ?
                    <>
                        <div onClick={scrollToUpload} className="w-full flex flex-col items-center  mt-[40px] rounded-[16px] max-w-[290px] py-[20px] bg-[#fff] shadow-[0_0_2px_0_rgba(0,0,0,0.16),0_2px_12px_0_rgba(0,0,0,0.12)]">
                            <DangerIcon />
                            <div className="mt-[20px] text-center max-w-[260px] text-[18px] text-[#242a46] font-bold leading-[1.33]">
                                {renderWithLineBreaks(t('SCORE_PREVIEW_UPDATE_MESSAGE'))}
                            </div>

                            <button className="w-[175px] mt-[20px] text-[17px] font-bold h-[38px] rounded-[22px] flex justify-center items-center border-[3px] border-[#232948] border-solid">
                                How to Improve
                            </button>
                        </div>

                        <div className="max-w-[260px] text-[#242a46] mt-[40px] text-base leading-[normal] font-bold flex justify-center text-center">
                            {renderWithLineBreaks(t('SCORE_PREVIEW_UPDATE_SELFIES_TITLE'))}
                        </div>

                        <div ref={uploadBlock} className=' mt-[20px] rounded-[48px] max-w-[290px] w-full flex flex-col items-center'>
                            <img src={uploadExampleSrc} alt="" className='h-[390px] w-[290px]' />

                            <button onClick={openUploadModal} className='flex mt-[10px] font-bold max-w-[290px]  w-full h-[64px] text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[16px]'>
                                {renderWithLineBreaks(t('SCORE_PREVIEW_UPDATE_SELFIES_BUTTON'))}
                            </button>
                        </div>

                        <PhotoLoadingModal onLoadPhotos={onUpdateScore} opened={uploadModalOpened} onClose={closeUploadModal} />
                        <AnalysisPreviewModal opened={analysisPreviewModalOpened} onClose={closeAnalysisPreviewModal} />
                        <TipsPreviewModal opened={tipsPreviewModalOpened} onClose={closeTipsPreviewModal} />
                    </> :
                    <ReferalBlock />
                }
            </>
        }

        {!isPreview && <>

            {isAuthorSession ?
                <>
                    {!!title && <div className="mt-[40px] text-[#242a46] text-center text-[16px] font-bold">
                        {title}
                    </div>}
                    {showBlock && <div
                        className={`${!!title ? 'mt-[20px]' : 'mt-[40px]'} pt-[18px] pb-[19px] flex flex-col gap-[21px] justify-center w-full max-w-[290px] bg-[#fff] rounded-[16px] shadow-[0_0_2px_0_rgba(0,0,0,0.16),0_2px_12px_0_rgba(0,0,0,0.12)]`}
                    >
                        <div className="text-[18px] text-[#232948] font-bold text-center leading-[1.33] tracking-[0.56px] flex justify-center items-center">
                            {renderWithLineBreaks(caption)}
                        </div>
                        <button onClick={runCompatibility} className="self-center cursor-pointer text-[#0076ff] border-solid border-[#0076ff] text-[17px] font-bold border-[3px] w-[150px] flex justify-center items-center rounded-[22px] h-[38px] ">
                            TRY
                        </button>
                    </div>}
                </>
                :
                <ReferalBlock />
            }

            <div ref={renderedResult} className="absolute left-[-1500px] w-[335px] h-[430px] bg-[#242a46] flex flex-col pt-[55px]">
                <img
                    alt=""
                    src={image}
                    className="self-center bg-cover border border-solid font-[15px] border-[#6c7183] w-[150px] h-[150px] rounded-[50%] bg-no-repeat bg-center"
                />
                <div className="flex justify-center mt-[30px]">
                    <SpeedometerCombined value={score || 50} />
                </div>
                <div className="flex justify-center mt-[21px]">
                    {status && (<div style={{ backgroundColor: colorOfScore.bg, color: colorOfScore.text }} className="text-[15px] h-[28px] rounded flex justify-center items-center px-3 text-white">
                        {status}
                    </div>)}
                </div>
            </div>

            <TipsModal opened={opened} onClose={closeModal} />
            <FeedbackModal opened={FeedbackModalOpened} onClose={closeFeedbackModal} />
        </>}

        <InsightsModal opened={openedInsightsModal} onClose={closeInsightModal} image={image} />

        <div className="flex mt-[30px] mb-[37px] text-center text-[13px] text-[#a4a6b2]">
            © FaceQ Beauty Lab
        </div>
    </div >
}
