import Modal from "../Modal"
import imageSrc from '../../images/tips-preview-image.webp';

export const TipsPreviewModal = ({ opened, onClose }) => {
    return (
        <Modal isOpen={opened} onClose={onClose} contentClassName="min-h-[477px] relative flex flex-col justify-end">
            <div className="w-full h-full p-[30px_36px_30px_46px] flex flex-col ">
                <div className="w-[200px] h-[200px] flex justify-center items-center self-center">
                    <img src={imageSrc} className="w-[134px] h-[147px] self-center" alt="" />
                </div>
                <div className="text-[#242a46] text-[18px] font-[500] text-center">
                    The tips is available once
                    <br />
                    you have uploaded
                    <br />
                    additional selfies
                </div>

                <button onClick={onClose} className="mt-[55px] cursor-pointer text-[#ffffff]  bg-[#0076ff] text-[20px] font-bold w-full flex justify-center items-center rounded-[16px] h-[64px] ">
                    OK
                </button>
            </div>
        </Modal>
    )
}