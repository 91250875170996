import { makeRequest } from "./request";
import React from "react";

export const getColorByType = (type) => {
    switch (type) {
        case 'HOT':
            return { bg: '#ff5656', text: '#ffffff' };
        case 'STUNNING':
            return { bg: '#ff8888', text: '#000000' };
        case 'ATTRACTIVE':
            return { bg: '#fee114', text: '#000000' };
        case 'CUTE':
            return { bg: '#d1d80f', text: '#000000' };
        case 'AVERAGE':
            return { bg: '#84bd32', text: '#000000' };
        case 'UNIQUE':
            return { bg: '#30ad43', text: '#ffffff' };
        case 'PROCESSING':
            return { bg: '#fee114', text: '#242a46' };
        default:
            return { bg: '#30ad43', text: '#ffffff' };
    }
}

export const getTypeByScore = (score) => {
    if (score >= 90) {
        return 'HOT'
    }
    if (score >= 80) {
        return 'STUNNING'
    }
    if (score >= 70) {
        return 'ATTRACTIVE'
    }
    if (score >= 60) {
        return 'CUTE'
    }
    if (score >= 30) {
        return 'AVERAGE'
    }
    return "UNIQUE"
}

export const loadResultScore = async (photoId, paywallMethod, onChangeProgress, isExpressMode = false) => {

    let loadedResult = false
    if (!photoId) {
        return;
    }
    while (!loadedResult) {
        const response = await makeRequest(isExpressMode ? '/score/express/status' : '/score/advanced/status', 'post', {
            score_id: photoId,
            paywall: paywallMethod === 'none' ? undefined : paywallMethod,
        }, {}, false);
        if (response.data.status && response.data.result.status === 1) {
            loadedResult = true;
        } else if (response.data.status === false && response.data.message) {
            return response;
        } else {
            onChangeProgress?.(response.data?.result?.progress || '');

            await waitFor(1000);
        }
    }

    const respResult = await makeRequest(isExpressMode ? `/score/express/result` : '/score/advanced/result', 'post', {
        score_id: photoId,
        paywall: paywallMethod === 'none' ? undefined : paywallMethod,
    }, {}, false);

    return respResult

}


export const loadCharacter = async (photoId, onChangeProgress, onTimeoutExtended, timeoutNumber) => {
    let loadedResult = false;

    setTimeout(() => {
        if (!loadedResult) {
            loadedResult = true;
            onTimeoutExtended();
        }
    }, timeoutNumber * 1000)

    while (!loadedResult) {
        const response = await makeRequest(`/image/status`, 'post', {
            score_id: photoId
        }, {}, false);

        if (response.data.status && response.data.result.url) {
            return response;
        } else {
            onChangeProgress(response.data.result.progress);
            await waitFor(1000);
        }
    }
}

export const waitFor = (time) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve()
        }, [time])
    })
}


export const isTelegram = () => {
    const keys = Object.keys(window.Telegram?.WebView?.initParams || {});
    return keys.length > 0;
}

export function isAndroid() {
    return /Android/i.test(navigator.userAgent);
}

export const renderWithLineBreaks = (text) => {
    return text.split('\n').map((part, index) => (
        <React.Fragment key={index}>
            {part}
            {index < text.split('\n').length - 1 && <br />}
        </React.Fragment>
    ));
};


export const imageUrlToBase64 = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
        reader.onerror = reject;
    });
};