import { getColorByType, getTypeByScore, renderWithLineBreaks } from "../../utils";
import { DownloadIcon, ReloadWhiteIcon } from "../../icons";
import { SpeedometerCombined } from "../Speedometer";
import { useCallback, useRef } from "react";
import html2canvas from "html2canvas-pro";

export const ScorePreview = ({
    onClickGetScore,
    score,
    status,
    image
}) => {
    const colorTypeOfScore = getTypeByScore(score);
    const colorOfScore = getColorByType(colorTypeOfScore);
    const renderedResult = useRef();

    const getImage = useCallback(() => {
        if (renderedResult.current) {
            html2canvas(renderedResult.current).then((canvas) => {
                let img = canvas.toDataURL("image/jpeg");
                // window.open(img);

                const linkSource = img;
                const downloadLink = document.createElement('a');
                document.body.appendChild(downloadLink);

                downloadLink.href = linkSource;
                downloadLink.target = '_self';
                downloadLink.download = 'saved';
                downloadLink.click();
            }).catch((e) => {
                console.error(e);
            })
        }
    }, [])

    return (
        <>
            <div className="text-[26px] font-[600] text-[#232948] leading-[normal] text-center">
                Your
                <br />
                Express Score
            </div>
            <div className='rounded-[32px] mt-[21px] bg-[#242a46] h-[241px] w-[241px] pt-[44px] flex flex-col justify-center items-center relative'>
                <SpeedometerCombined value={score} />
                <div className="flex justify-center mt-[12px]">
                    {status && (<div style={{ backgroundColor: colorOfScore.bg, color: colorOfScore.text }} className="text-[15px] h-[28px] rounded flex justify-center items-center px-3 text-white">
                        {status}
                    </div>)}
                </div>

                <div onClick={getImage} className="w-[44px] h-[44px] cursor-pointer rounded-[50%] bg-opacity-30 bg-[#000] absolute top-[10px] right-[11px] flex items-center justify-center">
                    <DownloadIcon />
                </div>
            </div>
            <div className="mt-[20px] flex">
                <button onClick={onClickGetScore} className='font-bold ml-[10px] w-[240px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[32px]'>
                    Get Advanced Score
                </button>
            </div>

            <div ref={renderedResult} className="absolute left-[-1500px] w-[335px] h-[430px] bg-[#242a46] flex flex-col pt-[55px]">
                <img
                    alt=""
                    src={image}
                    className="self-center bg-cover border border-solid font-[15px] border-[#6c7183] w-[150px] h-[150px] rounded-[50%] bg-no-repeat bg-center"
                />
                <div className="flex justify-center mt-[30px]">
                    <SpeedometerCombined value={score || 50} />
                </div>
                <div className="flex justify-center mt-[21px]">
                    {status && (<div style={{ backgroundColor: colorOfScore.bg, color: colorOfScore.text }} className="text-[15px] h-[28px] rounded flex justify-center items-center px-3 text-white">
                        {status}
                    </div>)}
                </div>
            </div>
        </>
    )
}