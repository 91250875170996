import { useLayoutEffect, useRef } from "react";
import SBGHot from "../images/h@3x.webp";
import SBGStunning from "../images/s@3x.webp";
import SBGAttractive from "../images/a@3x.webp";
import SBGCute from "../images/c@3x.webp";
import SBGAverage from "../images/av@3x.webp";
import SBGUnique from "../images/u@3x.webp";
import SBGAnimation from "../images/w-b@3x.webp";
import CursorSrc from "../images/cursor-copy@3x.webp";

import '../styles/Animation.css'
import { getTypeByScore } from "../utils";

const minValue = 0;
const maxValue = 50;

export const SpeedometerCombined = ({ value = 90, onlyAnimation }) => {

    const cursorRef = useRef()
    const type = onlyAnimation ? 'ANIMATION' : getTypeByScore(value);
    useLayoutEffect(() => {
        if (cursorRef.current && value) {
            cursorRef.current.style.transform = `rotate(-90deg)`
            let resultValue = value
            let newReversed = false
            if (resultValue <= 50) {
                resultValue = 50 - value;
                newReversed = true
            } else {
                resultValue = value - 50
            }
            let newAngle = ((resultValue - minValue) / (maxValue - minValue)) * 90;
            newAngle = newAngle + newAngle * 0.04;
            if (newAngle > 90) {
                newAngle = 90;
            }

            setTimeout(() => {
                cursorRef.current.style.transform = `rotate(${newReversed ? -newAngle : newAngle}deg)`
            }, 300)
        }
    }, [value])



    let stylesCalculated = { transformOrigin: '50% 88%' };
    if (!onlyAnimation) {
        stylesCalculated = {
            ...stylesCalculated,
            transitionDuration: '0.5s',
            transitionProperty: 'transform'
        }
    }


    return <div className="w-full h-[108px] flex items-center justify-center ml-[-2px]">
        <div className="relative h-[108px] w-[200px]">
            <img
                ref={cursorRef}
                alt=""
                src={CursorSrc}
                style={stylesCalculated}
                className={`z-[3] absolute h-[85px] left-[calc(50%-9px)] bottom-[1px] ${onlyAnimation ? 'infiniteAnimated' : ''}`}
            />
            <div className="absolute z-[2]">
                {renderBG(type)}
            </div>
        </div>

    </div>
}


const renderBG = (type) => {

    return <img src={SBGAnimation} alt="" className="w-auto h-auto" />

    // switch (type) {
    //     case 'HOT':
    //         return <img src={SBGHot} alt="" className="w-auto h-auto" />
    //     case 'STUNNING':
    //         return <img src={SBGStunning} alt="" className="w-auto h-auto" />
    //     case 'ATTRACTIVE':
    //         return <img src={SBGAttractive} alt="" className="w-auto h-auto" />
    //     case 'CUTE':
    //         return <img src={SBGCute} alt="" className="w-auto h-auto" />
    //     case 'AVERAGE':
    //         return <img src={SBGAverage} alt="" className="w-auto h-auto" />
    //     case 'UNIQUE':
    //         return <img src={SBGUnique} alt="" className="w-auto h-auto" />
    //     case 'ANIMATION':
    //         return <img src={SBGAnimation} alt="" className="w-auto h-auto" />
    //     default:
    //         return <img src={SBGUnique} alt="" className="w-auto h-auto" />
    // }
}
