import { renderWithLineBreaks } from "../../utils";
import { ReloadWhiteIcon } from "../../icons";

export const PhotoApprove = ({
    onReload,
    onContinue,
    text,
    exampleSrc
}) => {
    return (
        <>
            <div className="text-[25px] font-[600] text-[#232948] leading-[normal] text-center">
                {renderWithLineBreaks(text || '')}
            </div>
            <img src={exampleSrc} alt="" className='rounded-[32px] mt-[20px] h-auto min-h-[241px] w-[241px]' />
            <div className="mt-[20px] flex">
                <button onClick={onReload} className='font-bold w-[64px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[50%]'>
                    <ReloadWhiteIcon />
                </button>

                <button onClick={onContinue} className='font-bold ml-[10px] w-[166px] h-[64px] flex text-xl text-[white] justify-center items-center bg-[#0076ff] rounded-[30px]'>
                    Continue
                </button>
            </div>
        </>
    )
}