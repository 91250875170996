import { act, useCallback, useReducer, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { makeRequest } from "../utils/request";
import { useSelfieMode, useShouldShowPaywall, useShowExpressScore } from ".";
import { imageUrlToBase64, loadResultScore } from "../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const initialState = {
    photoFullFace: {
        url: null,
        id: null,
    },
    photoFullFaceError: null,
    photoProfile: {
        url: null,
        id: null,
    },
    previewData: {
        score: 0,
        status: '',
    },
    loading: false,
    photoProfileError: null,
    showingPaywall: false,
    showingPreview: false,
}

function reducer(state, action) {
    if (action.type === 'reset') {
        return initialState;
    }

    if (action.type === 'start_loading') {
        return {
            ...state,
            loading: true,
        }
    }

    let modifiedState = { ...state, loading: false, };

    if (action.type === 'loaded_full_face') {
        return {
            ...modifiedState,
            photoFullFace: {
                url: action.url,
                id: action.id,
            },
            photoFullFaceError: null,
        }
    }


    if (action.type === 'show_preview') {
        return {
            ...modifiedState,
            showingPreview: true,
            previewData: {
                score: action.score,
                status: action.status,
            }
        }
    }

    if (action.type === 'close_preview') {
        return {
            ...modifiedState,
            showingPreview: false,
        }
    }

    if (action.type === 'show_paywall') {
        return {
            ...modifiedState,
            showingPaywall: true,
        }
    }
    if (action.type === 'close_paywall') {
        return {
            ...modifiedState,
            showingPaywall: false,
        }
    }

    if (action.type === 'loaded_profile') {
        return {
            ...modifiedState,
            photoProfile: {
                url: action.url,
                id: action.id,
            },
            photoProfileError: null,
        }
    }
    if (action.type === 'full_face_error') {
        return {
            ...modifiedState,
            photoFullFaceError: {
                text: action.text,
                url: action.url,
            }
        };
    }
    if (action.type === 'profile_error') {
        return {
            ...modifiedState,
            photoProfileError: {
                text: action.text,
                url: action.url,
            }
        };
    }
}

export const PhotoLoadingContext = createContext({
    modalLoadingOpened: false,
    loadingProgres: true,
})

PhotoLoadingContext.displayName = 'AppContext'


export const PhotoLoadingContextProvider = ({ children }) => {
    const shouldShowPaywall = useShouldShowPaywall();
    const [progress, setProgress] = useState('');
    const [state, dispatch] = useReducer(reducer, initialState);
    const [modalLoadingOpened, setModalLoadingOpened] = useState(false);
    const [activeScoreId, setActiveScoreId] = useState(null);
    const selfieMode = useSelfieMode();
    const history = useHistory();
    const showExpress = useShowExpressScore();

    const openModal = useCallback(() => {
        setModalLoadingOpened(true);
    }, [])

    const closeModal = useCallback(() => {
        setModalLoadingOpened(false);
    }, [])

    const getStage = useCallback(() => {
        if (state.loading) {
            return 'loading';
        }

        if (state.showingPaywall) {
            return 'paywall';
        }

        if (state.showingPreview) {
            return 'preview';
        }

        if (state.photoFullFaceError) {
            return 'fullFaceError';
        }

        if (state.photoProfileError) {
            return 'profileError';
        }

        if (!state.photoProfile.id) {
            return 'profileLoad'
        }

        return 'loading';
    }, [state])

    const goToResult = useCallback(async (paywallMethod) => {
        dispatch({
            type: 'start_loading',
        })

        if (selfieMode === 'one' && !showExpress) {
            history.push(`/${activeScoreId}`);
            return;
        }

        loadResultScore(activeScoreId, paywallMethod, setProgress).then(() => {
            history.push(`/${activeScoreId}`);
        })
    }, [history, activeScoreId, selfieMode, showExpress])

    const startUploadFullFacePhoto = useCallback(async (photoBlob, photoSrc) => {
        openModal();
        dispatch({ type: 'start_loading' });
        // some async code
        const formData = new FormData();
        formData.append('file', photoBlob)
        formData.append('selfie_type', 0);
        const result = await makeRequest(`/selfie/upload`, 'post', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (!result.data.status || result.data.result.moderation.status !== 1) {
            if (result.data.status && result.data.result.moderation.status === 0)
                dispatch({
                    type: 'full_face_error',
                    text: result.data.message || result.data.result?.moderation?.comment || 'Internet Error',
                    url: result.data?.result?.url || photoSrc,
                })
            return;
        }

        let resultImage = null

        if (result.data?.result?.url) {
            imageUrlToBase64(result.data?.result?.url).then((val) => {
                resultImage = val;
            })
        } else {
            resultImage = photoSrc
        }


        const photoId = result.data.result.selfie_id;
        const creationResult = await makeRequest(`/score/create`, 'post', {
            selfie_id: photoId
        });



        if (!creationResult.data.status || creationResult.data.result.moderation !== 1) {
            dispatch({
                type: 'full_face_error',
                text: creationResult.data.message || creationResult.data.result?.moderation?.comment || 'Internet Error',
                url: creationResult.data?.result?.url || photoSrc,
            })
            return;
        }

        const { score_id } = creationResult.data.result;
        setActiveScoreId(score_id);

        let creationScoreResult;

        if (selfieMode === 'one') {
            if (showExpress) {
                creationScoreResult = await makeRequest('/score/express/create', 'post', {
                    score_id: score_id
                }, {}, false);
                // create express result right away
            } else {
                creationScoreResult = await makeRequest('/score/advanced/create', 'post', {
                    score_id: score_id
                }, {}, false);
                // create advanced result right away
            }
        }


        if (selfieMode === 'two') {
            creationScoreResult = await makeRequest('/score/express/create', 'post', {
                score_id: score_id
            }, {}, false);
            // create express result right away
        }


        if (!creationScoreResult.data.status) {
            dispatch({
                type: 'full_face_error',
                text: creationScoreResult.data.message || 'Internet Error',
                url: creationScoreResult.data?.result?.url || photoSrc,
            });
            return;
        }

        if (showExpress) {
            dispatch({
                type: 'start_loading'
            });
        }

        const scoreResult = await loadResultScore(score_id, 'none', setProgress, !(selfieMode === 'one' && !showExpress))

        if (scoreResult.data.status) {
            dispatch({
                type: 'loaded_full_face',
                url: resultImage || photoSrc,
                id: result.data.result.selfie_id,
            });

            if (selfieMode === 'one' && !showExpress) {
                if (shouldShowPaywall) {
                    dispatch({
                        type: 'show_paywall'
                    })
                } else {
                    history.push(`/${score_id}`)
                }
            } else {
                dispatch({
                    type: 'show_preview',
                    score: scoreResult.data.result.score,
                    status: scoreResult.data.result.status,
                });
            }
        } else {
            dispatch({
                type: 'full_face_error',
                text: scoreResult.data.message || 'Internet Error',
                url: scoreResult.data?.result?.url || photoSrc,
            })
        }

        setProgress('');
    }, [openModal, history, selfieMode, shouldShowPaywall, showExpress])

    const loadProfilePhoto = useCallback(async (photoBlob, photoSrc) => {
        dispatch({
            type: 'start_loading',
        })

        const formData = new FormData();
        formData.append('file', photoBlob)
        formData.append('selfie_type', 2)
        const result = await makeRequest(`/selfie/upload `, 'post', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (result.data.status && result.data.result.moderation.status === 1) {
            dispatch({
                type: 'loaded_profile',
                url: result.data?.result?.url || photoSrc,
                id: result.data.result.selfie_id,
            })

            const updateResult = await makeRequest('/score/advanced/create', 'post', {
                score_id: activeScoreId,
                selfie_side_id: result.data.result.selfie_id,
            });

            if (updateResult.data.status) {
                if (shouldShowPaywall) {
                    dispatch({
                        type: 'show_paywall'
                    })
                } else {
                    goToResult('none');
                    // history.push(`/${activeScoreId}`);
                    // go to result
                }
            } else {
                dispatch({
                    type: 'profile_error',
                    text: result.data.result?.moderation?.comment || 'Internet Error',
                    url: result.data?.result?.url || photoSrc,
                })
            }
        } else {
            dispatch({
                type: 'profile_error',
                text: result.data.result?.moderation?.comment || 'Internet Error',
                url: result.data?.result?.url || photoSrc,
            })
        }
    }, [activeScoreId, goToResult, shouldShowPaywall]);



    const onGoOnPreview = useCallback(() => {
        if (selfieMode === 'one') {
            dispatch({
                type: 'start_loading'
            })

            // make request to update result
            if (shouldShowPaywall) {
                // create advanced score
                makeRequest('/score/advanced/create', 'post', {
                    score_id: activeScoreId,
                });

                dispatch({
                    type: 'show_paywall'
                })
            } else {
                dispatch({
                    type: 'start_loading'
                })
                // create advanced score
                makeRequest('/score/advanced/create', 'post', {
                    score_id: activeScoreId,
                }).then(() => {
                    goToResult('none');
                });

                // goToResult('none')
                // history.push(`/${activeScoreId}`)
            }
            return;
        }

        dispatch({
            type: 'close_preview'
        })
    }, [activeScoreId, selfieMode, goToResult, shouldShowPaywall])

    const reset = useCallback(() => {
        dispatch({
            type: 'reset'
        });
        setActiveScoreId(null)
    }, [dispatch])

    const value = {
        state,
        getStage,
        modalLoadingOpened,
        dispatch,
        onGoOnPreview,
        startUploadFullFacePhoto,
        reset,
        loadProfilePhoto,
        goToResult,
        progress,
        closeModal
    }

    return <>
        <PhotoLoadingContext.Provider value={value}>
            {children}
        </PhotoLoadingContext.Provider>
    </>
}

export const usePhotoState = () => useContextSelector(PhotoLoadingContext, value => value.state)
export const useProgress = () => useContextSelector(PhotoLoadingContext, value => value.progress)
export const useGoOnPreview = () => useContextSelector(PhotoLoadingContext, value => value.onGoOnPreview)
export const useGetStage = () => useContextSelector(PhotoLoadingContext, value => value.getStage)
export const useUploadFullFacePhoto = () => useContextSelector(PhotoLoadingContext, value => value.startUploadFullFacePhoto)
export const useDispatch = () => useContextSelector(PhotoLoadingContext, value => value.dispatch)
export const useReset = () => useContextSelector(PhotoLoadingContext, value => value.reset)
export const useLoadProfilePhoto = () => useContextSelector(PhotoLoadingContext, value => value.loadProfilePhoto)
export const useGoToResult = () => useContextSelector(PhotoLoadingContext, value => value.goToResult)
export const useModalLoadingOpened = () => useContextSelector(PhotoLoadingContext, value => value.modalLoadingOpened)
export const useCloseModal = () => useContextSelector(PhotoLoadingContext, value => value.closeModal)

