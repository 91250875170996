import { useCallback, useReducer } from "react";
import { makeRequest } from "../../utils/request";
import Modal from "../Modal";
import { LoadingIcon } from "../../icons";
import "../../styles/Animation.css";
import leftSideSelfie from '../../images/left-side-selfie.webp';
import rightSideSelfie from '../../images/right-side-selfie.webp';
import { PhotoLoading } from "./PhotoLoading";
import { useTranslation } from "react-i18next";
import { PhotoLoadingError } from "./PhotoError";
import { PhotoApprove } from "./PhotoApprove";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSetPhoto, useShouldShowPaywall, useShowError } from "../../context";


const initialState = {
    photoLeftFace: {
        url: null,
        id: null,
        approved: false,
    },
    photoLeftFaceError: null,
    photoRightFace: {
        url: null,
        id: null,
        approved: false,
    },
    loading: false,
    photoRightFaceError: null
}

const getStage = (state) => {

    if (state.loading) {
        return 'loading';
    }

    if (state.photoLeftFaceError) {
        return 'leftFaceError';
    }

    if (state.photoRightFaceError) {
        return 'rightFaceError';
    }

    if (!state.photoLeftFace.id) {
        return 'leftFaceLoad'
    }
    if (state.photoLeftFace.id && !state.photoLeftFace.approved) {
        return 'leftFaceApprove'
    }

    if (!state.photoRightFace.id) {
        return 'rightFaceLoad'
    }

    if (state.photoRightFace.id && !state.photoRightFace.approved) {
        return 'rightFaceApprove'
    }

    return 'done';
}

function reducer(state, action) {

    if (action.type === 'reset') {
        return initialState;
    }

    if (action.type === 'start_loading') {
        return {
            ...state,
            loading: true,
        }
    }

    let modifiedState = { ...state, loading: false };

    if (action.type === 'approve_left_face') {
        return {
            ...modifiedState,
            photoLeftFace: {
                ...state.photoLeftFace,
                approved: true,
            }
        }
    }


    if (action.type === 'disapprove_left_face') {
        return {
            ...modifiedState,
            photoLeftFace: {
                url: null,
                id: null,
                approved: false,
            }
        }
    }
    if (action.type === 'disapprove_right_face') {
        return {
            ...modifiedState,
            photoRightFace: {
                url: null,
                id: null,
                approved: false,
            }
        }
    }

    if (action.type === 'loaded_left_face') {
        return {
            ...modifiedState,
            photoLeftFace: {
                url: action.url,
                id: action.id,
                approved: false,
            },
            photoLeftFaceError: null,
        }
    }
    if (action.type === 'loaded_right_face') {
        return {
            ...modifiedState,
            photoRightFace: {
                url: action.url,
                id: action.id,
                approved: false,
            },
            photoRightFaceError: null,
        }
    }
    if (action.type === 'left_face_error') {
        return {
            ...modifiedState,
            photoLeftFaceError: {
                text: action.text,
                url: action.url,
            }
        };
    }
    if (action.type === 'right_face_error') {
        return {
            ...modifiedState,
            photoRightFaceError: {
                text: action.text,
                url: action.url,
            }
        };
    }
}


export const PhotoLoadingModal = ({ opened, onClose, onLoadPhotos }) => {

    const [state, dispatch] = useReducer(reducer, initialState);
    const { t } = useTranslation();

    const closeHandler = useCallback(() => {
        dispatch({
            type: 'reset'
        });
        onClose();
    }, [onClose])

    const loadLeftFacePhoto = useCallback(async (photoBlob, photoSrc) => {
        dispatch({
            type: 'start_loading',
        })
        const formData = new FormData();
        try {
            formData.append('file', photoBlob)
            formData.append('selfie_type', 3)
            const result = await makeRequest(`/selfie/upload `, 'post', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (result.data.status && result.data.result.moderation.status === 1) {
                dispatch({
                    type: 'loaded_left_face',
                    url: result.data?.result?.url || photoSrc,
                    id: result.data.result.selfie_id,
                })
            } else {
                dispatch({
                    type: 'left_face_error',
                    text: result.data.message || result.data.result?.moderation?.comment || 'Internet Error',
                    url: result.data?.result?.url || photoSrc,
                })
            }
        } catch (e) {
            console.error(e);
        }

    }, []);



    const loadRightPhoto = useCallback(async (photoBlob, photoSrc) => {
        dispatch({
            type: 'start_loading',
        })

        const formData = new FormData();
        formData.append('file', photoBlob)
        formData.append('selfie_type', 4)
        const result = await makeRequest(`/selfie/upload `, 'post', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (result.data.status && result.data.result.moderation.status === 1) {
            dispatch({
                type: 'loaded_right_face',
                url: result.data?.result?.url || photoSrc,
                id: result.data.result.selfie_id,
            })
        } else {
            dispatch({
                type: 'right_face_error',
                text: result.data.result?.moderation?.comment || 'Internet Error',
                url: result.data?.result?.url || photoSrc,
            })
        }
    }, []);

    const approveLeftPhoto = useCallback(() => {
        dispatch({
            type: 'approve_left_face',
        });
    }, []);

    const disapproveLeftPhoto = useCallback(() => {
        dispatch({
            type: 'disapprove_left_face',
        });
    }, []);

    const approveRightPhoto = useCallback(async () => {
        // we send to backend
        dispatch({
            type: 'start_loading',
        })

        onLoadPhotos(state.photoLeftFace.id, state.photoRightFace.id)
    }, [state, onLoadPhotos]);

    const disapproveRightPhoto = useCallback(() => {
        dispatch({
            type: 'disapprove_right_face',
        });
    }, []);
    const stage = getStage(state);

    const renderContent = (state) => {
        const stage = getStage(state);
        switch (stage) {
            case 'loading':
                return (
                    <div className=" absolute top-[calc(50%-13px)] left-[calc(50%-12px)]">
                        <LoadingIcon className="loader" />
                    </div>
                )
            case 'leftFaceApprove':
                return (
                    <PhotoApprove text={t('UPLOAD_SELFIE_3_STEP_1_TITLE')} exampleSrc={state.photoLeftFace.url} onContinue={approveLeftPhoto} onReload={disapproveLeftPhoto} />
                )
            case 'rightFaceApprove':
                return (
                    <PhotoApprove text={t('UPLOAD_SELFIE_3_STEP_2_TITLE')} exampleSrc={state.photoRightFace.url} onContinue={approveRightPhoto} onReload={disapproveRightPhoto} />
                )
            case 'leftFaceError':
                return (
                    <PhotoLoadingError onPhotoLoaded={loadLeftFacePhoto} text={state.photoLeftFaceError.text} exampleSrc={state.photoLeftFaceError.url} />
                )
            case 'rightFaceError':
                return (
                    <PhotoLoadingError onPhotoLoaded={loadRightPhoto} text={state.photoRightFaceError.text} exampleSrc={state.photoRightFaceError.url} />
                )
            case 'leftFaceLoad':
                return (
                    <PhotoLoading onPhotoLoaded={loadLeftFacePhoto} text={t('UPLOAD_SELFIE_3_STEP_1_TITLE')} exampleSrc={leftSideSelfie} />
                )
            case 'rightFaceLoad':
                return (
                    <PhotoLoading onPhotoLoaded={loadRightPhoto} text={t('UPLOAD_SELFIE_3_STEP_2_TITLE')} exampleSrc={rightSideSelfie} />
                )
            default:
                return (
                    <div className="self-center">
                        <LoadingIcon className="loader" />
                    </div>
                )
        }
    }

    return (
        <Modal isOpen={opened} onClose={closeHandler} hideCloseButton={stage === 'loading'} contentClassName="min-h-[477px] relative">
            <div className="flex w-full justify-center">
                <div className="w-[241px] flex flex-col pt-[40px] items-center pb-[30px]">
                    {renderContent(state)}
                </div>
            </div>
        </Modal>
    )
}