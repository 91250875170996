import React from "react";

import "./bar.css";
import { twMerge } from 'tailwind-merge'

const CircleProgressBar = ({ score, className }) => {

    const fullLength = 17.9155 * 2 * Math.PI;

    const strokeDasharray = `${fullLength * (score / 100)}, ${fullLength * ((100 - score) / 100)}`;

    return (
        <svg width="80" height="80" viewBox="0 0 42 42" className={twMerge("circular-chart", className)}>
            <circle
                className="circle-bg"
                cx="21"
                cy="21"
                r="19"
            />
            <circle
                className="circle"
                cx="21"
                cy="21"
                r="19"
                strokeDasharray={strokeDasharray}
                transform="rotate(-70 21 21)"
            />

        </svg>
    );
};

export default CircleProgressBar;