import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Result } from "../components/Result/ResultPage";
import { Loader } from "../components/Loader";
import { isTelegram, loadCharacter, loadResultScore } from "../utils";
import { useHistory } from "react-router-dom/";
import { usePaywallMethod, useSetPaywallMethod, useShouldShowPaywall, useShowError, useTimerDelay } from "../context";
import { getUdid, makeRequest } from "../utils/request";

export const ResultPage = () => {

    const history = useHistory();

    let { photoId } = useParams();
    const alreadyLoading = useRef()
    const [isLoading, setIsLoading] = useState(true);
    const [isCharacterLoading, setIsCharacterLoading] = useState(true);
    const [characterCaption, setCharacterCaption] = useState('');
    const [characterUrl, setCharacterUrl] = useState('');
    const [result, setResult] = useState(null)
    const paywallMethod = usePaywallMethod();
    const [progress, setProgress] = useState('');
    const showError = useShowError();
    const [isPreview, setIsPreview] = useState(true);
    const [hideCharacter, setHideCharacter] = useState(false);

    const shouldShowPaywall = useShouldShowPaywall();

    const [characterProgress, setCharacterProgress] = useState('')
    const setPaywallMethod = useSetPaywallMethod();

    const [timeoutFallenFetch, setTimeoutFallenFetch] = useState(false);
    const timerDelay = useTimerDelay();


    const onGoAnalysis = useCallback(() => {
        history.push(`/analysis/${photoId}`);
    }, [photoId, history])

    const setSelectedTimeout = useCallback(() => {
        setTimeoutFallenFetch(true)
    }, [])

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', isTelegram() ? '#FFFFFF' : '#f6f7f8');
    }, [])

    useEffect(() => {
        if (result?.selfie_url) {
            const description = document.querySelector(`meta[property='og:description']`);
            description.setAttribute('content', 'Check Out How the AI Rates Me!');

            const imageUrlMeta = document.querySelector(`meta[property='og:image']`);
            imageUrlMeta.setAttribute('content', result?.selfie_url);

        }
    }, [result?.selfie_url])


    const loadResult = useCallback(async () => {
        loadResultScore(photoId, paywallMethod, setProgress).then((result) => {
            if (result.data.status) {
                setResult(result.data.result)
                setCharacterCaption(result.data.result?.image?.caption || '')
                setIsLoading(false)
                alreadyLoading.current = false;

                if (result.data.result.is_preview === 1) {
                    setIsPreview(true);
                } else {
                    setIsPreview(false);
                }

                if(result.data.result.image === undefined){
                    setHideCharacter(true);
                    return;
                }

                if (result.data.result.image.url) {
                    setIsCharacterLoading(false);
                    setCharacterUrl(result.data.result.image.url);
                } else {
                    loadCharacter(photoId, setCharacterProgress, setSelectedTimeout, timerDelay).then((result,) => {
                        if (!result) {
                            return;
                        }
                        if (result?.data?.status) {
                            setIsCharacterLoading(false);
                            setCharacterUrl(result.data.result.url);
                            setHideCharacter(false);
                            setPaywallMethod('none');
                        } else {
                            showError(result.data.message || 'No Internet Connection');
                            console.error('error', result);
                            history.push('/err');
                        }
                    })
                }

            } else {
                console.error('error', result.data)
                showError(result.data.message || 'No Internet Connection');
                history.push('/err');
            }
        }).catch((e) => {
            console.error('result err', e)
            history.push('/err');
            setIsLoading(false);
        })
    }, [photoId, history, paywallMethod, setPaywallMethod, showError, timerDelay, setSelectedTimeout])


    useEffect(() => {
        if (photoId && paywallMethod) {
            if (alreadyLoading.current === true) {
                return;
            }
            alreadyLoading.current = true;
            loadResult();
        }
    }, [loadResult, paywallMethod, photoId])


    const onUpdateScore = useCallback(async (leftSelfieId, rightSelfieId) => {
        setIsLoading(true);
        const result = await makeRequest(`/score/update`, 'post', {
            score_id: photoId,
            selfie_side_left_id: leftSelfieId,
            selfie_side_right_id: rightSelfieId,
        });

        if (shouldShowPaywall) {
            history.push(`/pay/${photoId}`);
            return;
        }

        if (result.data.status) {
            loadResult();
        } else {
            history.push('/err');
            setIsLoading(false);
        }
    }, [loadResult, history, photoId, shouldShowPaywall])


    const ratings = useMemo(() => {
        if (!result) {
            return []
        }
        const ratings = result.ratings;
        let ratingsResult = ratings.slice(0, 4);
        return ratingsResult;
    }, [result])

    if (!isLoading && !result) {
        return <div>error loading</div>
    }

    return <>
        {isLoading ? <div>
            <Loader progress={progress} />
        </div> : <Result
            hideCharacter={hideCharacter}
            timeoutFallenFetch={timeoutFallenFetch}
            characterProgress={characterProgress}
            characterUrl={characterUrl}
            characterCaption={characterCaption}
            isCharacterLoading={isCharacterLoading}
            photoSrc={result.selfie_url}
            score={result.score}
            status={result.status}
            reviews={result.reviews}
            isAuthorSession={result.udid === getUdid()}
            ratings={ratings}
            photoId={photoId}
            onGoAnalysis={onGoAnalysis}
            isPreview={isPreview}
            onUpdateScore={onUpdateScore}
            potential={result.score_potential}
        />}
    </>
}
