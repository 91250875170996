
import './App.css';

import { ResultPage } from './pages/ResultPage'

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { MainPage } from './pages/MainPage';
import { TermsPage } from './pages/TermsPage';
import { PrivacyPage } from './pages/PrivacyPage';
import { ErrorPage } from './pages/ErrorPage';
import ScrollToTop from './components/ScrollToTop';
import { AppContextProvider } from './context';
import { PhotoLoadingContextProvider } from './context/PhotoLoadingContext';
import { PaywallPage } from './pages/PaywallPage';
import { AnalysisPage } from './pages/AnalysisPage';
import { HistoryPage } from './pages/HistoryPage';
import { TipsPaywallPage } from './pages/TipsPaywallPage';
import { FeedbackPage } from './pages/FeedbackPage';


function App() {

    return (
        <AppContextProvider>
            <Router>
                <ScrollToTop />
                <Switch>
                    <Route path="/err">
                        <ErrorPage />
                    </Route>

                    <Route path="/ter">
                        <TermsPage />
                    </Route>

                    <Route path="/pri">
                        <PrivacyPage />
                    </Route>
                    <Route path="/history">
                        <HistoryPage />
                    </Route>

                    <Route path="/pay/:photoId">
                        <PaywallPage />
                    </Route>
                    <Route path="/pay-tips/:photoId">
                        <TipsPaywallPage />
                    </Route>

                    <Route path="/analysis/:photoId">
                        <AnalysisPage />
                    </Route>
                    <Route path="/feedback/:photoId">
                        <FeedbackPage />
                    </Route>

                    <Route path="/:photoId">
                        <ResultPage />
                    </Route>
                    <PhotoLoadingContextProvider>
                        <Route path="/">
                            <MainPage />
                        </Route>
                    </PhotoLoadingContextProvider>
                </Switch>
            </Router>
        </AppContextProvider>

    );
}

export default App;
