import { useCallback, useEffect, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { makeRequest } from "../utils/request";

export const AppContext = createContext({
    errorText: '',
    isErrorShown: false,
    photoSrc: '',
})

AppContext.displayName = 'AppContext'

export const AppContextProvider = ({ children }) => {
    const [errorText, setErrorText] = useState('');
    const [isErrorShown, setIsErrorShown] = useState(false);
    const [photoSrc, setPhotoSrc] = useState('')
    const [paywallMethod, setPaywallMethod] = useState('none');
    const [shouldShowPaywall, setShowPaywall] = useState(true);
    const [selfieMode, setSelfieMode] = useState(''); // can be 'one' or 'two'
    const [timeoutDelay, setTimeoutDelay] = useState(45);
    const [allowedNotifications, setAllowedNotifications] = useState(false);
    const [allowedPhotoShow, setAllowPhotoShow] = useState(false);
    const [initLoading, setInitLoading] = useState(false);
    const [blockSettings, setBlockSettings] = useState({
        showBlock: false,
        title: '',
        caption: '',
    });
    const [showExpressScore, setShowExpressScore] = useState(false);
    const [showSharing, setShowSharing] = useState(false);

    const [historyBlockSettings, setHistoryBlockSettings] = useState({
        photoCount: 0,
        photoSrc,
        caption: '',
        photoId: '',
    })

    useEffect(() => {
        const url = new URL(window.location.href);
        const utm_medium = url.searchParams.get("utm_medium") || undefined;
        const utm_source = url.searchParams.get("utm_source") || undefined;
        const utm_campaign = url.searchParams.get("utm_campaign") || undefined;
        const utm_term = url.searchParams.get("utm_term") || undefined;
        const utm_content = url.searchParams.get("utm_content") || undefined;
        const locale = navigator.language || undefined;
        makeRequest('/prepare', 'post', {
            utm_campaign,
            utm_medium,
            utm_content,
            utm_source,
            utm_term,
            locale
        }).then((response) => {
            if (response.data.status) {
                setTimeoutDelay(response.data.result.image_generation_time_limit);

                // setAllowedNotifications(response.data.result.allow.notifications === 1)
                // setAllowPhotoShow(response.data.result.allow.photo === 1)

                // setInitLoading(false);

                if (response.data.result.upload.selfie_cnt) {
                    setSelfieMode(response.data.result.upload.selfie_cnt === 1 ? 'one' : 'two');
                }
                if (response.data.result.is_express === 1) {
                    setShowExpressScore(true);
                }

                if (response.data.result.is_sharing_view === 1) {
                    setShowSharing(true);
                }

                if (response.data.result.block_cnt) {
                    setBlockSettings({
                        showBlock: response.data.result.block_cnt.view === 1,
                        title: response.data.result.block_cnt.title,
                        caption: response.data.result.block_cnt.caption,
                    })
                }

                if (response.data.result?.history?.cnt) {
                    setHistoryBlockSettings({
                        photoCount: response.data.result.history.cnt,
                        caption: response.data.result.history.caption,
                        photoSrc: response.data.result.history.photo_url,
                        photoId: response.data.result.history.photo_id,
                    })
                }


                if (response.data.result.block_by_paywall === 0) {
                    setShowPaywall(false);
                    return;
                }
            }
            setShowPaywall(true);
        });
    }, [setShowPaywall, setTimeoutDelay])

    const showError = useCallback((text) => {
        setErrorText(text);
        setIsErrorShown(true);
    }, [])

    const hideError = useCallback((text) => {
        setErrorText('');
        setIsErrorShown(false);
    }, [])

    const setPhoto = useCallback((photoSrc) => {
        setPhotoSrc(photoSrc)
    }, [])

    const value = {
        errorText,
        isErrorShown,
        photoSrc,
        showError,
        hideError,
        setPhoto,
        paywallMethod,
        setPaywallMethod,
        shouldShowPaywall,
        setShowPaywall,
        timeoutDelay,
        setTimeoutDelay,
        allowedPhotoShow,
        allowedNotifications,
        initLoading,
        blockSettings,
        historyBlockSettings,
        selfieMode,
        showExpressScore,
        showSharing
    }


    return <>
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    </>
}


export const useShowError = () => useContextSelector(AppContext, value => value.showError)
export const useHideError = () => useContextSelector(AppContext, value => value.hideError)
export const useErrorText = () => useContextSelector(AppContext, value => value.errorText)
export const useErrorShown = () => useContextSelector(AppContext, value => value.isErrorShown)
export const useSetPhoto = () => useContextSelector(AppContext, value => value.setPhoto)
export const usePhoto = () => useContextSelector(AppContext, value => value.photoSrc)
export const useSetPaywallMethod = () => useContextSelector(AppContext, value => value.setPaywallMethod)
export const usePaywallMethod = () => useContextSelector(AppContext, value => value.paywallMethod)
export const useShouldShowPaywall = () => useContextSelector(AppContext, value => value.shouldShowPaywall)
export const useSetShowPaywall = () => useContextSelector(AppContext, value => value.setShowPaywall)

export const useTimerDelay = () => useContextSelector(AppContext, value => value.timeoutDelay)
export const useSetTimeoutDelay = () => useContextSelector(AppContext, value => value.setTimeoutDelay)
export const useAllowedPhotoShow = () => useContextSelector(AppContext, value => value.allowedPhotoShow)
export const useAllowedNotifications = () => useContextSelector(AppContext, value => value.allowedNotifications)
export const useInitLoading = () => useContextSelector(AppContext, value => value.initLoading)
export const useBlockSettings = () => useContextSelector(AppContext, value => value.blockSettings)
export const useHistoryBlockSettings = () => useContextSelector(AppContext, value => value.historyBlockSettings)
export const useSelfieMode = () => useContextSelector(AppContext, value => value.selfieMode)
export const useShowExpressScore = () => useContextSelector(AppContext, value => value.showExpressScore)
export const useShowSharing = () => useContextSelector(AppContext, value => value.showSharing)
