import { useHistory, useParams } from "react-router-dom"
import { SpeedometerCombined } from "../components/Speedometer"
import { getColorByType, isTelegram, renderWithLineBreaks } from "../utils"
import { usePaywallMethod, usePhoto, useSetPaywallMethod, useSetPhoto } from "../context"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

export const PaywallPage = () => {
    const colorOfScore = getColorByType('PROCESSING')

    const history = useHistory();
    let { photoId } = useParams();
    const photoSrc = usePhoto()
    const setPaywallMethod = useSetPaywallMethod()
    const paywallMethod = usePaywallMethod();
    const setPhoto = useSetPhoto()
    const { t } = useTranslation();

    useEffect(() => {
        if (paywallMethod !== 'none') {
            history.push(`/${photoId}`)
        }
    }, [paywallMethod, history, photoId])

    const inviteFriends = () => {
        setPaywallMethod('invite');
        window.dataLayer.push({ 'event': 'invite_3_friends' });
        setPhoto('')
    }

    const payDollar = () => {
        setPaywallMethod('unlock');
        window.dataLayer.push({ 'event': 'unlock_for_$1' });

        setPhoto('')
    }

    useEffect(() => {
        document.querySelector('meta[name="theme-color"]').setAttribute('content', isTelegram() ? '#FFFFFF' : '#f6f7f8');
        document.querySelector('body').style = `background-color: ${isTelegram() ? '#FFFFFF' : '#f6f7f8'}`
    }, [])

    return (
        <div className={`${isTelegram() ? 'bg-[#FFFFFF]' : 'bg-[#f6f7f8]'} flex flex-col items-center relative justify-center py-[100px] sm:[py-38px] px-[20px]`}>
            <div className="sm:mt-[43px] font-header text-[28px] leading-[normal] color-[#232948] text-center">
                {renderWithLineBreaks(t('PAYWALL_1_TITLE'))}
            </div>

            <div className="mt-[12px] font-header w-[250px] text-center text-[18px] leading-[normal] text-[#242a46]">
                {renderWithLineBreaks(t('PAYWALL_1_SUBTITLE'))}
            </div>


            <div className="mt-[17px] relative h-[430px] flex flex-col pt-[55px] rounded-[50px] bg-[#242a46] max-w-[335px] w-[335px] md:self-center">
                <div
                    style={{ backgroundImage: `url("${photoSrc}")` }}
                    className="self-center bg-cover border border-solid font-[15px] border-[white] w-[150px] h-[150px] rounded-[50%] bg-no-repeat bg-center"
                />

                <div className="flex justify-center mt-6">
                    <SpeedometerCombined onlyAnimation={true} value={0} />
                </div>

                <div className="flex justify-center mt-[22px]">
                    <div style={{ backgroundColor: colorOfScore.bg, color: colorOfScore.text }} className="text-[15px] h-[28px] rounded flex justify-center items-center px-3 text-white">
                        {renderWithLineBreaks(t('PAYWALL_1_STATUS'))}
                    </div>
                </div>
            </div>

            {/* <button onClick={inviteFriends} className='font-bold mt-[16px] w-[320px] h-[60px] flex text-[18px] text-[#fdfcff] justify-center items-center bg-[#242a46] rounded-[30px]'>
                {renderWithLineBreaks(t('PAYWALL_INVITE'))}
            </button> */}

            <button onClick={payDollar} className='font-bold mt-[16px] w-[320px] h-[60px] flex text-[18px] text-[#fdfcff] justify-center items-center bg-[#0175ff] rounded-[30px]'>
                {renderWithLineBreaks(t('PAYWALL_1_BUTTON_UNLOCK'))}
            </button>

        </div>
    )
}