import Lottie from 'react-lottie';
import * as animationData from '../animations/loading.json'

export const Loader = ({ progress }) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div className={`flex flex-col items-center justify-center ${progress && 'mt-[36px]'} h-[95vh] w-[98vw] absolute top-0 left-0`}>
            <Lottie options={defaultOptions}
                height={250}
                width={250}
            />
            {progress && <>
                <div className='h-[72px] text-[18px] tracking-[normal] leading-[1.33] font-header font-bold text-[#232948] text-center '>
                    Process could take
                    <br />
                    a few minutes
                    <br />
                    {progress}
                </div>
            </>
            }
        </div>
    )
}